<template>
  <div>
    <v-card
      v-if="documentData"
      class="pa-2"
    >
      <v-card-title class="subheading font-weight-bold info--text">
        <span>{{ documentData.title }}</span>
      </v-card-title>
      <v-card-subtitle
        class="d-flex"
      >
        <span>{{ documentData.description }}</span>
        <v-spacer />
        <v-btn
          class="mt-3"
          link
          :to="{name:'archivedProcedureView',params:{id:documentData.procedure_id}}"
        >
          TORNA ALLA LISTA DOCUMENTI
        </v-btn>
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text>
        <div
          class="d-flex justify-end"
        >
          <v-spacer />
          <v-btn
            v-if="documentData.documentFiles_count > 0"
            color="primary"
            :loading="loadingAll"
            @click="downloadAllWithAxios(documentData.title+'.zip')"
          >
            <v-icon>
              mdi-download
            </v-icon>
            Scarica intero documento
          </v-btn>
        </div>
        <v-container
          v-if="finalDocumentFiles"
          class="mt-3"
        >
          <v-row
            v-for="(user, index) in finalDocumentFiles"
            :key="index"
          >
            <v-col
              cols="1"
            >
              <procedure-users-avatar :user="user.user" />
            </v-col>
            <v-col
              cols="11"
              align-self="center"
            >
              <h3>{{ user.user.fullName }}</h3>
              <h4 v-if="user.user.company">
                {{ user.user.company.name }}
              </h4>
            </v-col>
            <v-col
              v-for="(file,idx) in user.files"
              :key="idx"
              cols="3"
            >
              <file-card
                :procedure-id="documentData.procedure_id"
                :download-url="downloadUrl(file.id)"
                :file-obj="file"
                :upload-enabled="false"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import FileCard from '@/components/cards/file-card.vue'
import ProcedureUsersAvatar from '@/components/procedure/procedure-users-avatar.vue'

export default {
  components: {
    FileCard,
    ProcedureUsersAvatar,
  },
  data() {
    return {
      is_show: false,
      procedureData: {},
      documentData: {},
      documentUsers: {},
      documentCompanies: {},
      documentFiles: {},
      finalDocumentFiles: {},
      loading: { 0: false },
      loadingAll: false,
      breadCrumb: [
        {
          text: 'Dashboard',
          to: '/dashboard',
        },
        {
          text: 'Pratiche archiviate',
          to: '/archived-procedures',
        },
        {
          text: 'Dettaglio pratica',
          to: `/archived-procedure/${this.$route.params.id}/view`,
        },
        {
          text: 'Documento',
          to: `/archived-procedure/${this.$route.params.id}/documents/${this.$route.params.did}`,
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['loggedUser', 'archivedProcedureList']),
  },
  mounted() {
    this.getData()
    this.$store.dispatch('setBreadCrumbLevels', this.breadCrumb)
  },
  methods: {
    async getData() {
      const procedureId = parseInt(this.$route.params.id, 10)
      this.procedureData = await this.archivedProcedureList.find(item => item.id === procedureId)

      // prendo gli utenti dei documenti
      this.documentUsers = this.procedureData.users
      this.documentUsers = this.documentUsers.filter(el => el !== null)

      const documentId = parseInt(this.$route.params.did, 10)
      this.documentData = await this.procedureData.documents.find(item => item.id === documentId)
      this.upload_url = `${this.$apiUrl}procedures/${this.procedureData.id}/documents/${documentId}`

      this.documentFiles = this.documentData.document_files.map(el => el)

      const documentFilesPro = {}
      const documentFilesUser = {}

      // eslint-disable-next-line array-callback-return
      this.documentFiles.map(el => {
        if (el.document_creator === 'professional') {
          if (!(el.user_id in documentFilesPro)) {
            documentFilesPro[el.user_id] = {
              user: el.user,
              files: {},
            }
          }

          documentFilesPro[el.user_id].files[el.id] = el
        } else {
          if (!(el.user_id in documentFilesUser)) {
            documentFilesUser[el.user_id] = {
              user: el.user,
              files: {},
            }
          }

          documentFilesUser[el.user_id].files[el.id] = el
        }
      })

      this.finalDocumentFiles = { ...documentFilesPro, ...documentFilesUser }
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },
    downloadWithAxios(fid, title, idx) {
      const url = `${this.$apiUrl}procedures/${this.$route.params.id}/documents/${this.$route.params.did}/files/${fid}`
      axios({
        method: 'get',
        url,
        responseType: 'arraybuffer',
      })
        .then(response => {
          this.forceFileDownload(response, title)
          this.setLoading(idx)
        })
        .catch(() => console.log('error occured'))
    },
    downloadAllWithAxios(title) {
      this.loadingAll = true
      const url = `${this.$apiUrl}procedures/${this.$route.params.id}/documents/${this.$route.params.did}/download/all`
      axios({
        method: 'get',
        url,
        responseType: 'arraybuffer',
      })
        .then(response => {
          this.forceFileDownload(response, title)
          this.loadingAll = false
        })
        .catch(() => {
          console.log('error occured')
          this.loadingAll = false
        })
    },
    setLoading(idx) {
      if (this.loading[idx] === undefined) {
        this.$set(this.loading, idx, true)
      } else {
        this.loading[idx] = !this.loading[idx]
      }
    },
    formatWeight(size) {
      let finalSize = size / 1000

      // mega
      if (finalSize > 1000) {
        finalSize /= 1000

        return `${finalSize.toFixed(2)} MB`
      }

      return `${finalSize.toFixed(0)} KB`
    },
    downloadUrl(fileId) {
      return `${this.$apiUrl}procedures/${this.$route.params.id}/documents/${this.$route.params.did}/files/${fileId}`
    },
  },
}
</script>
